import {SceneJsonExporterData} from '@polygonjs/polygonjs/src/engine/io/json/export/Scene';
import {SceneJsonImporter} from '@polygonjs/polygonjs/src/engine/io/json/import/Scene';
import {HTMLController} from './controllers/HTMLController';
import {PolyRegister} from './polygonjs/scenes/default/register';
import {PolySceneWithNodeMap} from './polygonjs/scenes/default/PolySceneWithNodeMap';
import {ASSETS_ROOT} from './SceneConfig';
import {MainController} from './MainController';
const manifestContent = require('./polygonjs/scenes/default/manifest.json');
const sceneName = 'default';

async function loadSceneDataManifestImporter() {
	const {SceneDataManifestImporter} = await import(
		/* webpackChunkName: "SceneDataManifestImporter" */ '@polygonjs/polygonjs/src/engine/io/manifest/import/SceneData'
	);
	return SceneDataManifestImporter;
}
async function loadPolyRegister() {
	const {PolyRegister} = await import(/* webpackChunkName: "PolyRegister" */ './polygonjs/scenes/default/register');
	return PolyRegister;
}
async function loadSceneJsonImporter() {
	const {SceneJsonImporter} = await import(
		/* webpackChunkName: "SceneJsonImporter" */ '@polygonjs/polygonjs/src/engine/io/json/import/Scene'
	);
	return SceneJsonImporter;
}

async function loadHTMLController() {
	const {HTMLController} = await import(/* webpackChunkName: "HTMLController" */ './controllers/HTMLController');
	return HTMLController;
}
async function loadMainController() {
	const {MainController} = await import(/* webpackChunkName: "MainController" */ './MainController');
	return MainController;
}

type LoadJsPromiseArray = [
	Promise<typeof PolyRegister>,
	Promise<typeof SceneJsonImporter>,
	Promise<typeof MainController>,
	Promise<typeof HTMLController>
];
interface LoadJs {
	PolyRegister: typeof PolyRegister;
	SceneJsonImporter: typeof SceneJsonImporter;
	MainController: typeof MainController;
	HTMLController: typeof HTMLController;
}

async function loadJs(): Promise<LoadJs> {
	const promises: LoadJsPromiseArray = [
		loadPolyRegister(),
		loadSceneJsonImporter(),
		loadMainController(),
		loadHTMLController(),
	];
	const result = await Promise.all(promises);
	return {
		PolyRegister: result[0],
		SceneJsonImporter: result[1],
		MainController: result[2],
		HTMLController: result[3],
	};
}

async function loadSceneData() {
	const SceneDataManifestImporter = await loadSceneDataManifestImporter();
	const sceneData = await SceneDataManifestImporter.importSceneData({
		manifest: manifestContent,
		urlPrefix: `${ASSETS_ROOT}/polygonjs/scenes/${sceneName}`,
	});
	return sceneData;
}

async function loadImages() {
	const textureNames: string[] = ['bg.1024.png', 'land.512.jpg', 'f.512.jpg'];
	const urls = textureNames.map((textureName) => `${ASSETS_ROOT}/tex/${textureName}`);
	const promises = urls.map((url) => fetch(url));
	const results = await Promise.all(promises);
	return {
		bg: results[0],
		land: results[1],
		f: results[2],
	};
}

type LoadScenePromiseArray = [Promise<SceneJsonExporterData>, Promise<LoadJs>, Promise<any>];

async function loadScene() {
	const promises: LoadScenePromiseArray = [loadSceneData(), loadJs(), loadImages()];
	const result = await Promise.all(promises);
	return {
		sceneData: result[0],
		js: result[1],
	};
}

export class AssetsLoader2 {
	constructor() {}
	async load() {
		document.addEventListener('DOMContentLoaded', () => {
			this.loadScene();
		});
		this.loadScene();
	}
	private _sceneLoaded = false;
	async loadScene() {
		if (this._sceneLoaded) {
			return;
		}
		this._sceneLoaded = true;
		const time_start = performance.now();
		const data = await loadScene();
		const {PolyRegister, SceneJsonImporter, MainController, HTMLController} = data.js;

		PolyRegister.run();
		const importer = new SceneJsonImporter(data.sceneData);
		const scene = (await importer.scene()) as PolySceneWithNodeMap;

		// scene.node('/perspective_camera_MASTER/renderers1/webgl_renderer1').p.antialias.set(false);
		// Poly.renderersController.setPrintDebug(true);

		scene.assets.setRoot(ASSETS_ROOT);

		scene.node('/COP/file_bg_shadow').requestContainer();
		scene.node('/COP/file_f').requestContainer();
		scene.node('/COP/file_land').requestContainer();

		const cameraNode = scene.node('/perspective_camera_MASTER');
		if (!cameraNode) {
			console.warn('no master camera found');
			return;
		}
		const container = document.getElementById(HTMLController.ID_APP);
		if (!container) {
			console.warn('no element to mount the viewer onto');
			return;
		}
		const viewer = cameraNode.createViewer(container, {autoRender: false});

		const mainController = new MainController(scene, viewer, container);
		window.globe = mainController;

		console.log('[webgl globe] scene build time:', performance.now() - time_start);
		mainController.init();
	}
}
